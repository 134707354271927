import React, { memo } from 'react';
import { LazyImage } from 'components/image';


const Index = ({ imgUrl, name, profession, viewUserProfile, handleBtnOnClick, btnText = 'View' }) => {


    return (
        <div className="max-w-sm mx-auto overflow-hidden bg-white rounded-lg shadow-md">
            <LazyImage className="w-[300px] h-56 object-cover cursor-pointer" src={imgUrl} alt="Profile" onClick={viewUserProfile} />
            <div className="flex flex-col items-center justify-center p-4">
                <h2 className="mt-3 text-2xl font-medium text-center text-black event-title">{name}</h2>
                {/* {profession ?
                    <h2 className="text-lg text-center text-subText">{profession}</h2>
                    :
                    <div className="h-[26px]"></div>
                } */}
                <div onClick={handleBtnOnClick} className="w-[140px] mt-4 bg-brandRed text-white py-2  rounded-md flex items-center justify-center cursor-pointer">
                    {btnText}
                </div>
            </div>
        </div>
    );
};

export default memo(Index)