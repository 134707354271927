import { getAuthFeaturedEvents } from 'apis/home'
import { EventOverviewCard } from 'components/Cards/event'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { convert24To12Hour, formatDate } from 'shared/dates'
import { EVENTS } from 'shared/router'
import { capitalize } from 'shared/textFormats'

function FeaturedEvents() {
    const [featuredEvents, setFeaturedEvents] = useState([])
    const [pagination, setPagination] = useState({
        nextPage: null,
        currentPage: 1
    })

    const handleFeaturedEvents = async (page) => {
        const res = await getAuthFeaturedEvents(page)
        if (res?.success) {
            setFeaturedEvents(prev => [...prev, ...res?.data])
            setPagination({
                nextPage: res?.pagination?.next_page,
                currentPage: res?.pagination?.current_page
            })
        }
    }

    useEffect(() => {
        handleFeaturedEvents(pagination.currentPage)
    }, [])

    return (
        <>
            <div className="text-[26px] font-semibold">Featured Events</div>
            <div className="flex flex-wrap gap-4 mt-4">
                {featuredEvents?.map((item) => (
                    <div key={item.id} className="w-full my-2 lg:w-[300px] max-h-[310px]">
                        <Link to={EVENTS.EVENT_DETAILS.replace(':slug', item.slug)} className="block h-full">
                            <EventOverviewCard
                                img={item.image}
                                eventName={capitalize(item?.title)}
                                fromToTime={`${formatDate(item.start_date)} ${convert24To12Hour(item.start_time)} to`}
                                fromToDate={`${formatDate(item.end_date)} ${convert24To12Hour(item.end_time)}`}
                                EventPrice={item.isFree ? true : (item?.isFreeForUser ? true : item?.price)}
                                isBooked={item?.isBooked || item?.isAlreadyBooked}
                                isFreeForUser={item?.isFreeForUser}
                                bg_color={'bg-white'}
                            />
                        </Link>
                    </div>
                ))}
                {pagination.nextPage &&
                    <div onClick={() => handleFeaturedEvents(pagination.nextPage)} className="mx-auto text-blue-500 underline cursor-pointer">see more</div>
                }
            </div>
        </>
    )
}

export default FeaturedEvents