import { Calender } from "components/UI";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

function Index({ data, handleNext }) {
    const [availableDates, setAvailableDates] = useState({});
    const [selectedDates, setSelectedDates] = useState([]);
    const [events, setEvents] = useState([]);


    const mapAvailableDates = () => {
        return data.reduce((acc, current) => {
            const hasFreeSlot = current.times.some((time) =>
                ["free", "requested", "reject", "cancel", "requested"].includes(time.isBooked)
            );
            if (hasFreeSlot) {
                const formattedDate = new Date(current.date).toISOString().split("T")[0];
                acc[formattedDate] = true;
            }
            return acc;
        }, {});
    };

    const handleDateSelect = (info) => {
        const selectedDate = info.startStr;

        if (!availableDates[selectedDate]) {
            toast.error("User is not available on the selected date");
            return;
        }
        const isEventAlreadySelected = events.some(
            (event) => event.start === selectedDate && event.title === "Selected"
        );

        setSelectedDates(() => {
            if (isEventAlreadySelected) {
                setEvents((prevEvents) =>
                    prevEvents.filter((event) => !(event.start === selectedDate && event.title === "Selected"))
                );
                return [];
            } else {
                setEvents((prevEvents) =>
                    prevEvents.filter((event) => event.title !== "Selected")
                );

                setEvents((prevEvents) => [
                    ...prevEvents,
                    {
                        title: "Selected",
                        start: selectedDate,
                        className: "event-selected",
                    },
                ]);
                return [selectedDate];
            }
        });
    };




    useEffect(() => {
        if (data && data.length > 0) {
            const response = mapAvailableDates();
            setAvailableDates(response);
            const events = Object?.keys(response)?.map((date) => ({
                title: "Available",
                start: date,
                className: "event-available",
            }));
            setEvents(events)
        }
    }, [data]);

    const handleSubmit = () => {
        if (selectedDates?.length === 0) {
            toast.error('Date is required')
            return
        }
        handleNext(selectedDates, 2)
    }


    return (
        <div className="">
            <div className="border shadow-2xl">
                <Calender events={events} handleDateSelect={handleDateSelect} />
            </div>

            <div className="flex items-center justify-center my-10 space-x-4">
                <div onClick={handleSubmit} className="p-2 px-6 text-white cursor-pointer rounded-3xl bg-brandRed">Next step</div>
            </div>

        </div>
    );
}

export default Index;
