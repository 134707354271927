import React from "react";

// PREV
import AllPopups from 'components/popUps/allPopups'
import AllEmptyCards from 'components/Cards/emptyCards/allEmptyCards'

// license
import TermsAndConditions from "pages/license/TermsAndConditions";
import PrivacyPolicy from "pages/license/PrivacyPolicy";

import { AUTH, BLOG, BOOKING_PAYMENT, CONNECTIONS, CUPID, ERROR, EVENTS, POPUPS, GPS, LICENSE, MAIN_NAV, OFFERS, ON_BOARDING_PAGES, PROFILE_EDIT, SETTINGS, SITE_INTO, WALLET, EMPTY_CARDS, CONVERSATION } from "../shared/router";

// On Boarding
import { OnBoardingFirstPage, OnBoardingSecondPage, OnBoardingThirdPage, OnBoardingFourthPage, OnBoardingFifthPage, OnBoardingSixthPage, OnBoardingSeventhPage } from "pages/onBoarding"

// Profile Edit start
import { BasicInformation, DietAttributes, EducationDetails, FamilyDetails, GroomsLocation, Interest, Languages, PhotoAndGallery, PhysicalAttributes } from "pages/ProfileEdit"

// SITE INTRO
import { Landing, AboutUs, Events, HostWithUs, Datrimony, Blogs, BlogDetail, AppDownload } from "pages/siteIntro"

//  Auth
import { Welcome, Login, Signup } from 'pages/auth'

// Event 
import { EventDetailedPage, EventFilterPage, EventBooking } from "pages/Event"

// Settings Start
import { Settings, ProfilePreview, PartnerPreference, WalletSettings, PrivacySettings, AccountSettings, Logout } from 'pages/settings'

// Connection start
import { FriendsConnectionPage, SuggestedConnectionPage, EventsConnectionPage, RequestedConnectionPage, FriendsProfilePreviewPage, RequestedProfilePrev, QrConnectionProfilePrev, HandPicked } from 'pages/connections'

// Wallet Section
import { Wallet as WalletPageNew, WalletRecharge as WalletRechargePage, Plans as PlanesPage, WalletCard, History as WalletHistoryPage, QrCodeScanner as Scan } from 'pages/WalletSection'

// Offers
import { OffersList as OffersPage, OfferDetails as OffersDetailsPage } from 'pages/offers'

// Conversations
import { Chat as ChatPage, ScheduleMeeting, CreateMySlots, MeetingManagement } from 'pages/Conversations'

// GPS
import { Gps as GPSPage, GpsHistory as GPSHistoryPage } from "pages/gps"

// Error
import { ServerError } from 'pages/error'

import HomePage from "pages/Home"
import Discover from 'pages/Discover'

// cupid
import Cupid from 'pages/cupid'

import { authenticationProps, avoidOptions, LAYOUT_TYPES } from "utils/constant/routeHandler";




export const Links = [

    // ========== NEW VERSION START  ========== //
    //  SITE INTRO START
    {
        name: "Landing Page",
        auth: authenticationProps.NONE,
        layoutType: LAYOUT_TYPES.UNAUTHENTICATED,
        path: SITE_INTO.LANDING,
        element: <Landing />,
        showInNavigation: true,
    },
    {
        name: "About Us Page",
        auth: authenticationProps.NONE,
        layoutType: LAYOUT_TYPES.UNAUTHENTICATED,
        path: SITE_INTO.ABOUT,
        element: <AboutUs />,
        showInNavigation: true,
    },
    {
        name: "Event Home Page",
        auth: authenticationProps.NONE,
        layoutType: LAYOUT_TYPES.UNAUTHENTICATED,
        path: SITE_INTO.EVENT_HOME,
        element: <Events />,
        showInNavigation: true,
    },
    {
        name: "Host with us Page",
        auth: authenticationProps.NONE,
        layoutType: LAYOUT_TYPES.UNAUTHENTICATED,
        path: SITE_INTO.HOST_WITH_US,
        element: <HostWithUs />,
        showInNavigation: true,
    },
    {
        name: "Datrmony Page",
        auth: authenticationProps.NONE,
        layoutType: LAYOUT_TYPES.UNAUTHENTICATED,
        path: SITE_INTO.DATRIMONY,
        element: <Datrimony />,
        showInNavigation: true,
    },
    {
        name: "Blog Page",
        auth: authenticationProps.NONE,
        layoutType: LAYOUT_TYPES.UNAUTHENTICATED,
        path: SITE_INTO.BLOG,
        element: <Blogs />,
        showInNavigation: true,
    },
    {
        name: "Blog Detail Page",
        auth: authenticationProps.NONE,
        layoutType: LAYOUT_TYPES.UNAUTHENTICATED,
        path: BLOG.BLOG_DETAILS,
        element: <BlogDetail />,
        showInNavigation: true,
    },
    {
        name: "Blog Detail Page",
        auth: authenticationProps.NONE,
        layoutType: LAYOUT_TYPES.UNAUTHENTICATED,
        path: SITE_INTO.APP_DOWNLOAD,
        element: <AppDownload />,
        showInNavigation: true,
    },
    //  SITE INTRO END

    // AUTH START
    {
        name: "Signup",
        path: AUTH.SIGNUP,
        layoutType: LAYOUT_TYPES.NONE,
        auth: authenticationProps.UNAUTH,
        element: <Signup />,
        showInNavigation: true,
    },
    {
        name: "Login",
        layoutType: LAYOUT_TYPES.NONE,
        auth: authenticationProps.UNAUTH,
        path: AUTH.LOGIN,
        element: <Login />,
        showInNavigation: true,
    },
    {
        name: "Welcome Page",
        layoutType: LAYOUT_TYPES.NONE,
        auth: authenticationProps.UNAUTH,
        path: AUTH.WELCOME,
        element: <Welcome />,
        showInNavigation: true,
    },
    // AUTH END

    // ON BOARDING STARTS
    {
        name: "On Boarding Page One",
        layoutType: LAYOUT_TYPES.NONE,
        auth: authenticationProps.AUTH,
        path: ON_BOARDING_PAGES.FIRST,
        element: <OnBoardingFirstPage />,
        showInNavigation: true,
    },
    {
        name: "On Boarding Page Two",
        layoutType: LAYOUT_TYPES.NONE,
        auth: authenticationProps.AUTH,
        path: ON_BOARDING_PAGES.SECOND,
        element: <OnBoardingSecondPage />,
        showInNavigation: true,
    },
    {
        name: "On Boarding Page Three",
        layoutType: LAYOUT_TYPES.NONE,
        auth: authenticationProps.AUTH,
        path: ON_BOARDING_PAGES.THIRD,
        element: <OnBoardingThirdPage />,
        showInNavigation: true,
    },
    {
        name: "On Boarding Page Fourth",
        layoutType: LAYOUT_TYPES.NONE,
        auth: authenticationProps.AUTH,
        path: ON_BOARDING_PAGES.FOURTH,
        element: <OnBoardingFourthPage />,
        showInNavigation: true,
    },
    {
        name: "On Boarding Page Fifth",
        layoutType: LAYOUT_TYPES.NONE,
        auth: authenticationProps.AUTH,
        path: ON_BOARDING_PAGES.FIFTH,
        element: <OnBoardingFifthPage />,
        showInNavigation: true,
    },
    {
        name: "On Boarding Page Sixth",
        layoutType: LAYOUT_TYPES.NONE,
        auth: authenticationProps.AUTH,
        path: ON_BOARDING_PAGES.SIXTH,
        element: <OnBoardingSixthPage />,
        showInNavigation: true,
    },
    {
        name: "On Boarding Page Seventh",
        layoutType: LAYOUT_TYPES.NONE,
        auth: authenticationProps.AUTH,
        path: ON_BOARDING_PAGES.SEVENTH,
        element: <OnBoardingSeventhPage />,
        showInNavigation: true,
    },
    // ON BOARDING END

    // CONNECTIONS STARTS
    {
        name: "Discover",
        path: MAIN_NAV.DISCOVER,
        avoid: [avoidOptions.FOOTER],
        element: <Discover />,
        showInNavigation: true,
    },
    {
        name: "Cupid Page",
        path: CUPID,
        layoutType: LAYOUT_TYPES.NONE,
        auth: authenticationProps.NONE,
        element: <Cupid />,
        showInNavigation: true,
    },
    {
        name: "Connection Page Friends",
        path: CONNECTIONS.FRIENDS,
        element: <FriendsConnectionPage />,
        showInNavigation: true,
    },
    {
        name: "Connection Page HandPicked",
        path: CONNECTIONS.HANDPICK,
        element: <HandPicked />,
        showInNavigation: true,
    },
    {
        name: "Suggestion Page",
        path: CONNECTIONS.SUGGESTIONS,
        element: <SuggestedConnectionPage />,
        showInNavigation: true,
    },
    {
        name: "Events Connection Page",
        path: CONNECTIONS.EVENTS,
        element: <EventsConnectionPage />,
        showInNavigation: true,
    },
    {
        name: "Request Page",
        path: CONNECTIONS.REQUESTS,
        element: <RequestedConnectionPage />,
        showInNavigation: true,
    },
    {
        name: "Friends Prev Page",
        path: CONNECTIONS.FRIEND_PREVIEW,
        element: <FriendsProfilePreviewPage />,
        showInNavigation: true,
    },
    {
        name: "User Prev Page for connection request send and received user",
        path: CONNECTIONS.REQUESTED_CONNECTION_PROFILE_PREVIEW,
        element: <RequestedProfilePrev />,
        showInNavigation: true,
    },
    {
        name: "User Prev Page for qr Connection",
        path: CONNECTIONS.CONNECTION_FRIEND_PREVIEW,
        element: <QrConnectionProfilePrev />,
        showInNavigation: true,
    },
    // CONNECTIONS END

    // Home Page
    {
        name: "HomPage",
        path: MAIN_NAV.HOME,
        auth: authenticationProps.AUTH,
        element: <HomePage />,
        showInNavigation: true,
    },
    // EVENT PAGES START
    {
        name: "Event Detail Page authorized",
        path: EVENTS.EVENT_DETAILS,
        auth: authenticationProps.NONE,
        layoutType: LAYOUT_TYPES.NONE,
        element: <EventDetailedPage />,
        showInNavigation: true,
    },
    {
        name: "Event Booking page",
        path: BOOKING_PAYMENT,
        element: <EventBooking />,
        showInNavigation: true,
    },
    {
        name: "Event Filter Page",
        auth: authenticationProps.NONE,
        layoutType: LAYOUT_TYPES.NONE,
        path: EVENTS.EVENT_FILTER,
        element: <EventFilterPage />,
        showInNavigation: true,
    },
    //    EVENT PAGES END

    // Settings Start
    {
        name: "Settings",
        layoutType: LAYOUT_TYPES.SETTINGS,
        path: SETTINGS.SETTINGS,
        element: <Settings />,
        showInNavigation: true,
    },
    {
        name: "Profile Prev",
        layoutType: LAYOUT_TYPES.SETTINGS,
        path: SETTINGS.PROFILE_PREVIEW,
        element: <ProfilePreview />,
        showInNavigation: true,
    },
    // Profile Edit start
    {
        name: "Profile Edit Basic Information",
        path: PROFILE_EDIT.BASIC,
        element: <BasicInformation />,
        showInNavigation: true,
    },
    {
        name: "Profile Edit Physical Attributes",
        path: PROFILE_EDIT.PHYSICAL,
        element: <PhysicalAttributes />,
        showInNavigation: true,
    },
    {
        name: "Profile Edit Dies Attributes",
        path: PROFILE_EDIT.DIET,
        element: <DietAttributes />,
        showInNavigation: true,
    },
    {
        name: "Profile edit Location Details",
        path: PROFILE_EDIT.LOCATION,
        element: <GroomsLocation />,
        showInNavigation: true,
    },
    {
        name: "Profile Edit Family Details",
        path: PROFILE_EDIT.FAMILY_DETAILS,
        element: <FamilyDetails />,
        showInNavigation: true,
    },
    {
        name: "Profile Edit Photos",
        path: PROFILE_EDIT.PHOTO,
        element: <PhotoAndGallery />,
        showInNavigation: true,
    },
    {
        name: "Profile Edit Languages",
        path: PROFILE_EDIT.LANGUAGES,
        element: <Languages />,
        showInNavigation: true,
    },
    {
        name: "Profile Edit Interest",
        path: PROFILE_EDIT.INTERESTS,
        element: <Interest />,
        showInNavigation: true,
    },
    {
        name: "Profile Edit Education Details",
        path: PROFILE_EDIT.EDUCATION,
        element: <EducationDetails />,
        showInNavigation: true,
    },
    // Profile Edit End

    {
        name: "Partner Preference",
        layoutType: LAYOUT_TYPES.SETTINGS,
        path: SETTINGS.PARTNER_PREFERENCE,
        element: <PartnerPreference />,
        showInNavigation: true,
    },
    {
        name: "Privacy Settings",
        layoutType: LAYOUT_TYPES.SETTINGS,
        path: SETTINGS.PRIVACY_SETTINGS,
        element: <PrivacySettings />,
        showInNavigation: true,
    },
    {
        name: "Account Settings",
        layoutType: LAYOUT_TYPES.SETTINGS,
        path: SETTINGS.ACCOUNT_SETTINGS,
        element: <AccountSettings />,
        showInNavigation: true,
    },
    {
        name: "Wallet Settings",
        layoutType: LAYOUT_TYPES.SETTINGS,
        path: SETTINGS.WALLET,
        element: <WalletSettings />,
        showInNavigation: true,
    },
    {
        name: "lOGOUT Page",
        layoutType: LAYOUT_TYPES.SETTINGS,
        path: SETTINGS.LOGOUT,
        element: <Logout />,
        showInNavigation: true,
    },
    // Settings End

    // Wallet
    {
        name: "Wallet Page",
        path: WALLET.WALLET,
        layoutType: LAYOUT_TYPES.WALLET,
        element: <WalletPageNew />,
        showInNavigation: true,
    },
    {
        name: "Wallet Card Page",
        path: WALLET.CARD,
        layoutType: LAYOUT_TYPES.WALLET,
        element: <WalletCard />,
        showInNavigation: true,
    },

    {
        name: "Wallet Recharge Page",
        path: WALLET.RECHARGE,
        layoutType: LAYOUT_TYPES.WALLET,
        element: <WalletRechargePage />,
        showInNavigation: true,
    },
    {
        name: "planes Page",
        path: WALLET.PLANES,
        layoutType: LAYOUT_TYPES.WALLET,
        element: <PlanesPage />,
        showInNavigation: true,
    },
    {
        name: "wallet history Page",
        path: WALLET.HISTORY,
        layoutType: LAYOUT_TYPES.WALLET,
        element: <WalletHistoryPage />,
        showInNavigation: true,
    },
    {
        name: "Scan couple squad QR",
        path: WALLET.SCAN,
        element: <Scan />,
        showInNavigation: true,
    },


    // Offers
    {
        name: "OFFERS Page",
        path: OFFERS.OFFERS,
        element: <OffersPage />,
        showInNavigation: true,
    },
    {
        name: "OFFER DETAILS Page",
        path: OFFERS.OFFER_DETAILS,
        element: <OffersDetailsPage />,
        showInNavigation: true,
    },

    // Chat
    {
        name: "Chat Page",
        path: MAIN_NAV.CHAT,
        element: <ChatPage />,
        showInNavigation: true,
    },
    // Meetings
    {
        name: "Select the slot for the meeting",
        path: CONVERSATION.MEETING,
        element: <ScheduleMeeting />,
        showInNavigation: true,
    },
    {
        name: "Create My Slots",
        path: CONVERSATION.MEETING_SLOTS,
        element: <CreateMySlots />,
        showInNavigation: true,
    },
    {
        name: "Meeting Management",
        path: CONVERSATION.MEETING_MANAGEMENT,
        element: <MeetingManagement />,
        showInNavigation: true,
    },


    // LICENSE START
    {
        name: "Terms And Conditions Page",
        path: LICENSE.TERMS_AND_CONDITIONS,
        element: <TermsAndConditions />,
        showInNavigation: true,
    },
    {
        name: "Privacy Policy Page",
        path: LICENSE.PRIVACY_POLICIES,
        element: <PrivacyPolicy />,
        showInNavigation: true,
    },
    // GPS
    {
        name: "GPS Page",
        path: GPS.GPS,
        element: <GPSPage />,
        showInNavigation: true,
    },
    {
        name: "GPS History",
        path: GPS.HISTORY,
        element: <GPSHistoryPage />,
        showInNavigation: true,
    },
    // LICENSE END
    // ERROR PAGE START
    {
        name: "Server Error Page",
        path: ERROR.SERVER_ERROR,
        layoutType: LAYOUT_TYPES.NONE,
        element: <ServerError />,
        showInNavigation: true,
    },
    {
        name: "Popups",
        path: POPUPS,
        element: <AllPopups />,
        showInNavigation: true,
    },
    {
        name: "Error Cards",
        path: EMPTY_CARDS,
        element: <AllEmptyCards />,
        showInNavigation: true,
    },
    // ERROR PAGES END

    // ========== NEW VERSION END ==========
];
