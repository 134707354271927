import React, { lazy, Suspense, useEffect, useState } from "react";
import MeetingLabelCards from './components/usersLabelCard';
import { fetchMeetingsBasedOnStatus, updateMeetingRequestStatus } from "apis/meeting";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const MeetingPrev = lazy(() => import("./components/meetingRequestPreview"));

function Index() {
    const { id } = useSelector((state) => state.userProfile);

    const [selectedTab, setSelectedTab] = useState("request");
    const [pagination, setPagination] = useState({
        current_page: 1,
        next_page: null
    })
    const [search, setSearch] = useState("");
    const [meetings, setMeetings] = useState([]);
    const [isMeetingRequestPrev, setIsMeetingRequestPrev] = useState(false);
    const [selectedMeeting, setSelectedMeeting] = useState(null);

    const tabs = [
        { key: "request", label: "Request" },
        { key: "accept", label: "Accept" },
        { key: "history", label: "History" },
    ];

    const handleFetchMeetingsBasedOnStatus = async (page = 1) => {
        const res = await fetchMeetingsBasedOnStatus(selectedTab, page);
        if (res?.success) {
            setMeetings((prev) => [...prev, ...res?.data]);
            setPagination({ ...res?.pagination })
        }
    };

    const openMeetingPreview = (meeting) => {
        setSelectedMeeting(meeting);
        setIsMeetingRequestPrev(true);
    };

    const closeMeetingPreview = () => {
        setSelectedMeeting(null);
        setIsMeetingRequestPrev(false);
    };

    const handleUpdateMeetingRequestStatus = async (status, id) => {
        const res = await updateMeetingRequestStatus(status, id)
        if (res?.success) {
            toast.success('status updated')
            closeMeetingPreview()
            handleFetchMeetingsBasedOnStatus()
        }
    }

    useEffect(() => {
        handleFetchMeetingsBasedOnStatus(1);
        setMeetings([])
    }, [selectedTab]);

    return (
        <div className="flex min-h-screen text-black">

            {/* Popup Modal */}
            {isMeetingRequestPrev && (
                <div className="fixed inset-0 z-50 flex items-center justify-center w-full bg-black bg-opacity-50">
                    <Suspense fallback={<div>Loading...</div>}>
                        <MeetingPrev
                            meeting={selectedMeeting}
                            updateMeetingStatus={(status, id) => handleUpdateMeetingRequestStatus(status, id)}
                            handleClose={closeMeetingPreview}
                        />
                    </Suspense>
                </div>
            )}

            {/* Sidebar */}
            {/* <div className="w-full h-screen sm:w-[17.5%] hidden lg:block">
                <ChatSideBar />
            </div> */}

            {/* Main Content */}
            <div className="flex flex-col w-full p-5 mt-5">
                <h1 className="mb-6 text-2xl font-bold">Meetings</h1>
                <input
                    type="text"
                    placeholder="Search meetings..."
                    onChange={(e) => setSearch(e.target.value)}
                    value={search}
                    className="w-full p-3 mb-4 text-black bg-white border rounded-lg"
                />


                {/* Child Tabs */}
                <div className="flex w-full my-3 space-x-10">
                    {tabs?.map((item, index) => (
                        <div
                            key={index}
                            onClick={() => setSelectedTab(item?.key)}
                            className={`cursor-pointer text-base ${selectedTab === item?.key ? "text-brandRed border-b-2 font-semibold border-b-brandRed" : ""
                                }`}
                        >
                            {item?.label}
                        </div>
                    ))}
                </div>

                {/* Meeting Cards */}
                {meetings?.filter((meeting) =>
                    meeting?.user?.name?.toLowerCase()?.includes(search?.toLowerCase())
                )?.map((meeting) => (
                    <MeetingLabelCards
                        key={meeting.id}
                        photo={meeting.user?.photo}
                        name={meeting.user?.name}
                        location={meeting.location}
                        method={meeting.method}
                        isSend={id === meeting?.scheduledUserId ? true : false}
                        onAccept={selectedTab === "request" ? () => handleUpdateMeetingRequestStatus("accept", meeting.id) : false}
                        onDecline={selectedTab === "request" ? () => handleUpdateMeetingRequestStatus("reject", meeting.id) : false}
                        onPreview={() => openMeetingPreview(meeting)}
                    />
                ))}

                {/* No Meetings Found */}
                {meetings?.filter((meeting) =>
                    meeting?.user?.name?.toLowerCase()?.includes(search?.toLowerCase())
                )?.length === 0 && (
                        <p className="text-gray-500">No meetings found.</p>
                    )}

                {pagination?.next_page !== null &&
                    <div onClick={() => handleFetchMeetingsBasedOnStatus(pagination.next_page)} className="items-center w-full text-sm text-center text-blue-400 cursor-pointer">see more</div>
                }
            </div>

        </div>
    );
}

export default Index;
