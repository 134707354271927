import React, { lazy, Suspense, useEffect } from 'react';


import Banner from './components/banner';
import { Helmet } from 'react-helmet-async';

const WhatIsDatrimony = lazy(() => import('./components/whatIsDatrimony'))
const WhyChooseDatrimony = lazy(() => import('./components/whyChooseDatrimony'))
const InviteFriends = lazy(() => import('./components/inviteFriends'))
const Event = lazy(() => import('./components/event'))
const ConnectionFlow = lazy(() => import('./components/connectionFlow'))
const Testimonial = lazy(() => import('./components/testimonial'))
const Parterns = lazy(() => import('./components/parterns'))


const Index = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div>

            <Helmet>
                {/* <meta name='title' content="India's First and Only Datrimony Platform| Couple Squad" /> */}
                {/* <meta name="description" content="Couple Squad's Datrimony is India’s first and only platform that blends dating with matrimony, a unique way to connect and build lasting relationships." /> */}

                <title>India's First and Only Datrimony Platform| Couple Squad</title>
                <meta name="description" content="Couple Squad's Datrimony is India’s first and only platform that blends dating with matrimony, a unique way to connect and build lasting relationships." />
                <meta property="og:title" content="India's First and Only Datrimony Platform| Couple Squad" />
                <meta property="og:description" content="Couple Squad's Datrimony is India’s first and only platform that blends dating with matrimony, a unique way to connect and build lasting relationships." />
                <meta property="og:url" content="https://www.couplesquad.com/Datrimony" />
                <link rel="canonical" href="https://www.couplesquad.com/Datrimony" />
            </Helmet>


            <Banner />
            <Suspense children={<WhatIsDatrimony />} fallback={<div>loading</div>} />
            <Suspense children={<WhyChooseDatrimony />} fallback={<div>loading</div>} />
            {/* <Suspense children={<CpFeatures />} fallback={<div>loading</div>} /> */}
            <Suspense children={<InviteFriends />} fallback={<div>loading</div>} />
            <Suspense children={<Event />} fallback={<div>loading</div>} />
            <div className="mt-24">
                <Suspense children={<ConnectionFlow />} fallback={<div>loading</div>} />
            </div>
            <Suspense children={<Testimonial />} fallback={<div>loading</div>} />
            <Suspense children={<Parterns />} fallback={<div>loading</div>} />


        </div>
    );
};

export default Index;
