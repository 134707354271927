import { ChatSideBar } from "components/sidebars"
import UserChatBubble from "./components/chatPeoples"
import MessagesSection from "./components/messages"
import OnlineUsersList from "./components/onlineUsers"
import { LazyImage } from 'components/image';
import { useSelector } from 'react-redux';
import { toast } from "react-toastify"
import avatar from "assets/avatar.png"

// Assets

import blockIcon from "assets/chat/icon/block.png"
import { lazy, Suspense, useCallback, useEffect, useState } from "react"
import { fetchAllMessagedFriendsList, fetchMessagesOfUserById, searchUsersByName } from "apis/chat"
import { connectSocket, disconnectSocket, sendNewMessage, markMessageAsRead } from "../../../socket/SocketManager"
import { useSocketListenerWithConnectedSocket } from "hooks/socketListern"

const BlockAndReportPopUp = lazy(() => import("components/popUps").then(module => ({ default: module.BlockAndReportUser })))
const emptyMessagesIcon = `${process.env.REACT_APP_CLOUDINARY_IMAGE_BASEURL_V1}CoupleSquad%20Static%20Assets/Empty_Cards/No_Message_euiuhb`

const Index = () => {

    const userData = useSelector((state) => state.userProfile);

    const [isBelowMdDevice, setIsBelowMdDevice] = useState(true)
    const [threeDoteDropdown, setThreeDoteDropdown] = useState(false)
    const [selectedUser, setSelectedUser] = useState(false)
    const [blockAndUnBlockPopup, setBlockAndUnBlockPopup] = useState(false)
    const [text, setText] = useState()
    const [userInfo, setUserInfo] = useState({
        receiverPhoto: null,
        id: userData?.id,
        photo: userData?.photo,
        name: null,
        roomId: null,
        shortListedUserId: null,
        isBlocked: false,
    })

    const [messagedFriendsList, setMessagedFriendsList] = useState([])
    const [messages, setMessages] = useState([])
    const [pagination, setPagination] = useState({
        nextPage: 1,
        limitation: 20
    })
    const [searchTerm, setSearchTerm] = useState('');
    const [isSearching, setIsSearching] = useState(false);


    const getAllFriendsList = async () => {
        const res = await fetchAllMessagedFriendsList()
        setMessagedFriendsList(res?.data)
    }

    useEffect(() => {
        getAllFriendsList()
        connectSocket()

        return () => {
            setTimeout(() => {
                disconnectSocket();
            }, 300)
        };

    }, [])

    const handleFetchMessageOfUser = async (shortlistedUserId, roomId, newUser = false) => {
        let { nextPage, limitation } = pagination
        if (newUser) {
            const user = messagedFriendsList?.find((user) => user?.roomId === roomId)
            setUserInfo(prev => ({ ...prev, roomId: roomId, shortListedUserId: shortlistedUserId, name: user?.name, receiverPhoto: user?.photo, isBlocked: user?.blocked }))
            nextPage = 1
            setMessages([])
        }
        if (nextPage) {
            const res = await fetchMessagesOfUserById(shortlistedUserId, roomId, nextPage, limitation)
            setPagination({ nextPage: res?.pagination?.next_page, limitation: res?.pagination?.limit })
            setMessages(prev => ([...res?.data?.reverse(), ...prev]));
        }
        setSelectedUser(true)
    }

    const handleSentNewMessages = useCallback(() => {
        if (userInfo?.roomId) {
            if (text) {
                sendNewMessage(userInfo?.roomId, text)
                setText('')
            }
        } else {
            toast.error("Choose the person to whom you need to send the message")
        }
    }, [userInfo?.roomId, text])

    const handleMarkAsRead = useCallback((message_id, user = false) => {
        if (user) {
            setMessages(currentMessages =>
                currentMessages.map(msg =>
                    msg?.id === message_id ? { ...msg, read: true } : msg
                )
            );
        } else {
            markMessageAsRead(message_id)
        }
    }, []);

    const searchUsers = useCallback(async (data) => {
        const res = await searchUsersByName(data)
        if (res.success) {
            setMessagedFriendsList(res?.data)
        }
        setIsSearching(false);
    }, []);

    useEffect(() => {
        const handler = setTimeout(() => {
            if (searchTerm) {
                setIsSearching(true);
                searchUsers(searchTerm);
            }
        }, 2000);

        return () => {
            clearTimeout(handler)
        };
    }, [searchTerm, searchUsers]);


    // Socket Lister's
    // Socket Error Lister
    const handleErrorMessage = useCallback((data) => {
        toast.error(data?.message)
    }, [])


    // Socket Client Message Update Lister
    const handleNewClientMessage = useCallback((data) => {
        if (userInfo?.roomId === data?.res?.room_id) setMessages(prev => ([...prev, data?.res?.last_message]))
    }, [])

    // Client Mark Message Read Lister 
    const handleClientMessageRead = useCallback((data) => {
        setMessages(currentMessages =>
            currentMessages?.map(msg =>
                msg.id === data?.message_id ? { ...msg, read: true } : msg
            )
        );
    }, [])

    useSocketListenerWithConnectedSocket({
        "clientMessageRead": handleClientMessageRead,
        "clientMessage": handleNewClientMessage,
        "error": handleErrorMessage
    });


    useEffect(() => {

        const handleResize = () => {
            setIsBelowMdDevice(window.innerWidth <= 768);
        };
        // Initial check
        handleResize();
        // Add event listener
        window.addEventListener('resize', handleResize);
        // Clean up event listener on unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div>

            {blockAndUnBlockPopup &&
                <div className="fixed top-0 bottom-0 left-0 right-0 z-50 flex items-center justify-center bg-black bg-opacity-75">
                    <Suspense children={<BlockAndReportPopUp closeBtn={() => {
                        setBlockAndUnBlockPopup(false)
                    }} data={{
                        id: userInfo?.shortListedUserId,
                        photo: userInfo?.receiverPhoto,
                        name: userInfo?.name
                    }}
                        onSuccess={() => {
                            getAllFriendsList()
                            setThreeDoteDropdown(false)
                        }}
                        type={userInfo?.isBlocked ? "unBlock" : 'block'} />} />
                </div>
            }


            <div className="flex flex-col w-full sm:flex-row">
                {/* Chat SideBar (hidden for sm and md devices)*/}
                <div className="w-full h-screen sm:w-[17.5%] hidden lg:block">
                    <ChatSideBar />
                </div>

                {/* Chat People Section */}
                {(!isBelowMdDevice || (isBelowMdDevice && !selectedUser)) && (

                    <div className="w-full lg:max-h-screen bg-[#f9f9f9] sm:w-full lg:w-[26%] border-r border-r-gray-300">
                        <div className="w-10/12 mx-auto mt-10">

                            <div className="block lg:hidden">
                                <OnlineUsersList direction={'row'} />
                            </div>

                            <h1 className="text-3xl font-bold text-black">Chat</h1>
                            {/* Search option */}
                            <div className="relative my-6">
                                <svg width="25" height="25" className="absolute w-6 h-6 text-gray-500 top-3 left-7" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.5742 21.1348C16.8549 21.1348 21.1358 16.854 21.1358 11.5733C21.1358 6.29257 16.8549 2.01172 11.5742 2.01172C6.29355 2.01172 2.0127 6.29257 2.0127 11.5733C2.0127 16.854 6.29355 21.1348 11.5742 21.1348Z" stroke="#161616" stroke-width="1.50972" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M22.1428 22.1419L20.1299 20.1289" stroke="#161616" stroke-width="1.50972" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                                <input
                                    type="search"
                                    placeholder="Search messenger..."
                                    className="w-full h-12 text-black bg-white outline-none pl-14"
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                                {isSearching && <div>Searching...</div>}
                            </div>

                            {/* Chat Tab Options */}
                            <h1 className="pb-2 text-lg font-bold border-b text-brandRed border-b-brandRed w-fit">All chats</h1>

                            {/* Chat Peoples */}
                            <div className="overflow-y-auto  lg:max-h-[calc(96vh-200px)]">
                                {messagedFriendsList?.map((item) => (
                                    <div key={item?.roomId} className="mt-5">
                                        <UserChatBubble
                                            images={item?.photo ? item?.photo : avatar}
                                            isBlock={item?.blocked}
                                            name={item?.name}
                                            lastMessage={item?.lastMessage?.message}
                                            handleOnclick={() => handleFetchMessageOfUser(item.shortlistedUserId, item?.roomId, true)}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                )}

                {/* Message Section */}
                {(!isBelowMdDevice || (isBelowMdDevice && selectedUser)) && (
                    <div className="w-full h-screen bg-white lg:w-[46.75%]">
                        <div className="w-full lg:w-[96%] mt-6 mx-auto">
                            {userInfo?.receiverPhoto && (
                                <div className="flex items-center justify-between w-full ms-2">
                                    <>
                                        <LazyImage src={userInfo?.receiverPhoto} className="w-[75px] h-[70px] rounded-xl" />
                                        <p className="text-2xl font-bold text-black ms-14">{userInfo?.name}</p>
                                    </>
                                    <div className="relative">
                                        {/* Three Dots Button */}
                                        <button onClick={() => setThreeDoteDropdown(!threeDoteDropdown)} className="p-2">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 text-gray-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6V6.01M12 12V12.01M12 18V18.01M12 18V18.01" />
                                            </svg>
                                        </button>

                                        {/* Dropdown Menu */}
                                        {threeDoteDropdown && (
                                            <div className="absolute right-0 z-50 w-48 mt-2 bg-white rounded-md shadow-lg">
                                                <ul className="py-1">
                                                    <li>
                                                        <button onClick={() => setBlockAndUnBlockPopup(true)} className="flex w-full gap-2 px-4 py-2 text-sm text-left text-gray-700 hover:bg-gray-100">
                                                            <img src={blockIcon} alt="blockIcon" /> {userInfo?.isBlocked ? "Unblock" : 'Block'}
                                                        </button>
                                                    </li>
                                                </ul>
                                            </div>
                                        )}
                                    </div>
                                </div>

                            )}
                            {/* Scrollable container for messages */}
                            {messages?.length !== 0 ?
                                <div className="" style={{ height: '60vh', overflowY: 'auto' }}>
                                    <MessagesSection
                                        messages={messages}
                                        currentUserId={userInfo?.id}
                                        shortListedUserId={userInfo?.shortListedUserId}
                                        currentUserPhoto={userInfo?.photo ? userInfo?.photo : avatar}
                                        showMoreOption={(value) => handleFetchMessageOfUser(userInfo?.shortListedUserId, userInfo?.roomId, value)}
                                        markAsRead={handleMarkAsRead}
                                        isShowMore={pagination.nextPage !== null ? true : false}
                                    />

                                </div>
                                :
                                <div className="flex justify-center">
                                    <LazyImage src={emptyMessagesIcon} />
                                </div>
                            }
                        </div>
                        {userInfo?.roomId &&
                            <>
                                <div className="w-full border-b border-b-gray-300"></div>
                                <div className="w-full lg:w-[96%] mt-6 mx-auto flex justify-center items-center">
                                    {userInfo?.isBlocked ?

                                        <div onClick={() => setBlockAndUnBlockPopup(true)} className='w-full py-3 text-xl text-center text-white rounded-md cursor-pointer bg-brandRed'>Unblock</div>
                                        :

                                        <div className="relative w-full lg:w-10/12">
                                            <input type="text" placeholder="Message"
                                                value={text}
                                                className="w-full pl-2 pr-10 text-black bg-gray-200 outline-none h-14"
                                                onChange={(e) => setText(e.target.value)}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        handleSentNewMessages();
                                                    }
                                                }}
                                            />
                                            <div className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer" onClick={handleSentNewMessages}>
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M1.94584 9.31551C1.42384 9.14151 1.41884 8.86051 1.95584 8.68151L21.0438 2.32051C21.5728 2.14451 21.8758 2.44051 21.7278 2.95851L16.2738 22.0445C16.1238 22.5735 15.8188 22.5915 15.5948 22.0895L11.9998 14.0005L17.9998 6.00051L9.99984 12.0005L1.94584 9.31551Z" fill="#F83758" />
                                                </svg>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </>
                        }

                    </div>
                )}

                {/* Online Users (will be hidden for sm and md devices)*/}
                <div className="w-full h-screen bg-[#f9f9f9] sm:w-[9.75%] hidden lg:block">
                    <OnlineUsersList direction={'col'} />
                </div>

            </div>
        </div>
    );
};

export default Index;