import React, { lazy, Suspense, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

const Banner = lazy(() => import("./components/banner"))
const FeatureCard = lazy(() => import("./components/feature"))
const ServicesCard = lazy(() => import("./components/services"))
const DesktopAndMobileApp = lazy(() => import("./components/desktopAndMobileApp"))
const ConnectionsDisappear = lazy(() => import("./components/connectionsDisappear"))
const Verification = lazy(() => import("./components/verification"))
const PlanCard = lazy(() => import("./components/planCard"))
const Testimonial = lazy(() => import("./components/testimonial"))
const CpFeatures = lazy(() => import('../datrimony/components/cpFeatures'))

function Index() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div>
            <Helmet>
                {/* <meta name='title' content="Couple Squad: India's First Event-Based Social Community" />

                <meta name="description" content="
                   Join Couple Squad, India's first event-based social community. Find curated events, connect with like-minded people, and build meaningful relationships.
                "/>
                   */}

                <title>Couple Squad: India's First Event-Based Social Community</title>
                <meta name="description" content="Join Couple Squad, India's first event-based social community. Find curated events, connect with like-minded people, and build meaningful relationships." />
                <meta property="og:title" content="Couple Squad: India's First Event-Based Social Community" />
                <meta property="og:description" content="Join Couple Squad, India's first event-based social community. Find curated events, connect with like-minded people, and build meaningful relationships." />
                <meta property="og:url" content="https://www.couplesquad.com" />
                <link rel="canonical" href="https://www.couplesquad.com" />

            </Helmet>
            <div className=''>


                {/* Main Banners */}
                <div className="h-full min-h-screen mt-16">
                    <Suspense children={<Banner />} fallback={<div>loading...</div>} />
                </div>
                {/* FeatureCard */}
                <div className='mt-28 md:mt-0'>
                    <Suspense children={<CpFeatures />} fallback={<div>loading</div>} />
                    {/* <Suspense children={<FeatureCard />} fallback={<div>loading...</div>} /> */}
                </div>
                {/* Services */}
                <>
                    <Suspense children={<ServicesCard />} fallback={<div>loading...</div>} />
                </>
                {/* Desktop and Mobile App */}
                <>
                    <Suspense children={<DesktopAndMobileApp />} fallback={<div>loading...</div>} />
                </>
                {/* Connections Disappear */}
                <>
                    <Suspense children={<ConnectionsDisappear />} fallback={<div>loading...</div>} />
                </>
                {/* Verification  */}
                <>
                    <Suspense children={<Verification />} fallback={<div>loading...</div>} />
                </>

                {/* Plan Card */}
                <>
                    <Suspense children={<PlanCard />} fallback={<div>loading...</div>} />
                </>
                {/* Testimonial */}
                <>
                    <Suspense children={<Testimonial />} fallback={<div>loading...</div>} />
                </>


            </div>
        </div >
    );
}

export default Index;