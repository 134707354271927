import React, { lazy, Suspense, useCallback, useEffect, useState } from 'react';
import OptionsGrid from './components/optionsGrid';
import { useDispatch, useSelector } from 'react-redux';
import avatar from 'assets/avatar.png'
import { connectSocket, disconnectSocket, socketEmitter } from 'socket/SocketManager';
import { toast } from 'react-toastify';
import { useSocketListenerWithConnectedSocket } from 'hooks/socketListern';
import { ToggleWithText } from 'components/UI';
import { updateGhostMode } from 'apis/gps';
import { setUserProfileDetails } from '../../../redux/slice/userProfile';

const ScanningSection = lazy(() => import('./components/scanningSection'))
const PeoplesList = lazy(() => import('./components/peoplesList'))

function Index() {
    const dispatch = useDispatch();
    const [currentIndex, setCurrentIndex] = useState(1)
    const [location, setLocation] = useState({
        lat: '12.97',
        long: '77.59'
    });
    const [users, setUsers] = useState([])
    const userInfo = useSelector((state) => state.userProfile);
    const [ghostMode, setGhostMode] = useState(userInfo?.ghost_mode || false)


    const handleGhostMode = async (value) => {
        const data = { value: value }
        const res = await updateGhostMode(data)
        if (res?.success) {
            setGhostMode(res?.data?.ghost_mode)
            dispatch(setUserProfileDetails({
                ...userInfo,
                ghost_mode: res?.data?.ghost_mode
            }));
        }
    }

    const handleNewUsersStateUpdate = useCallback((newUsersData) => {
        const newUsersDataArray = Array.isArray(newUsersData) ? newUsersData : [newUsersData];
        setUsers((prevUsers) => {
            const updatedUsers = [...prevUsers];
            newUsersDataArray?.forEach((newUserData) => {
                const userIndex = updatedUsers?.findIndex((user) => user?.userId === newUserData?.userId);
                if (userIndex !== -1) {
                    updatedUsers[userIndex] = { ...updatedUsers[userIndex], ...newUserData };
                } else {
                    updatedUsers.push(newUserData);
                }
            });
            return updatedUsers;
        });
    }, [setUsers]);

    const handleUserExitStateUpdate = useCallback((userData) => {
        setUsers((prevUsers) => {
            const updatedUsers = prevUsers.filter(user => user.userId !== userData.userId);
            return updatedUsers;
        });
    }, [setUsers]);


    useEffect(() => {
        connectSocket();

        let data = null;
        let enterTimeout = null;

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                const { latitude, longitude } = position.coords;

                data = {
                    latitude,
                    longitude,
                    radius: 100
                };

                // Delay enter_location by 1 second
                enterTimeout = setTimeout(() => {
                    socketEmitter("enter_location", data);
                }, 1000);

                setLocation({ lat: latitude, long: longitude });
            });
        }

        return () => {
            if (enterTimeout) clearTimeout(enterTimeout); // Clear timeout on cleanup

            if (data !== null) {
                socketEmitter("exit_location", data);
            }

            disconnectSocket();
        };
    }, []);



    // useEffect(() => {
    //     // fetchLocation();
    //     return () => {
    //     }
    // }, [])
    // Socket Lister's
    // Socket Error Lister
    const handleErrorMessage = useCallback((data) => {
        toast.error(data?.message)
    }, [])

    // Socket For new users
    const handleNearByUsers = useCallback((data) => {
        handleNewUsersStateUpdate(data)
    }, [])

    // new nearby user
    const handleNewNearByUsers = useCallback((data) => {
        handleNewUsersStateUpdate(data)
    }, [])

    // nearby user Exit
    const handleNearByUserExit = useCallback((data) => {
        handleUserExitStateUpdate(data)
    }, [])

    useSocketListenerWithConnectedSocket({
        "nearby_users": handleNearByUsers,
        "new_nearby_user": handleNewNearByUsers,
        "user_exited": handleNearByUserExit,
        "error": handleErrorMessage
    });



    return (
        <div className="min-h-screen bg-white">

            {/* Wrapper for Centering the Content */}
            <div className="flex flex-col items-center justify-center w-full">

                {/* Header Section */}
                <div className="w-[96%] md:w-[80%] bg-[#6161f8] text-white rounded-lg shadow-lg p-6  my-10">
                    <div className='flex items-center justify-between'>
                        <div>
                            <h1 className="text-2xl font-bold">Welcome GPS Nearby Tracking</h1>
                            <p className="mt-2 text-sm">Always stay updated in Couple Squad Profile</p>
                        </div>
                        <img src={userInfo?.photo || avatar} alt='' className="hidden w-16 h-16 bg-gray-300 rounded-full md:block" />
                    </div>

                    <div className="w-full mt-8 border border-white rounded-lg">
                        <div className="p-4">

                            <div className="flex items-center justify-between w-full">
                                <div className="text-lg font-semibold">Ghost  Mode</div>
                                <button>
                                    <ToggleWithText isChecked={ghostMode} onToggle={() => handleGhostMode(!ghostMode)} />
                                </button>
                            </div>

                            <div className="">See who’s can see you around you! Connect with nearby members in real-time as</div>
                        </div>
                    </div>

                </div>

                {currentIndex === 1 &&
                    <div className="w-[96%] md:w-[80%] mb-20">
                        <OptionsGrid handleScan={() => setCurrentIndex(2)} />
                    </div>
                }

                {currentIndex === 2 &&
                    <div className="w-[96%] md:w-[80%] mb-[50%] md:mb-0">
                        <Suspense children={<ScanningSection handleComplete={() => setCurrentIndex(3)} users={users} />} fallback={<div>loading....</div>} />
                    </div>
                }
                {currentIndex === 3 &&
                    <div className="w-[96%] md:w-[80%] mb-20">
                        <Suspense children={<PeoplesList users={users} />} fallback={<div>loading....</div>} />
                    </div>
                }

            </div>

        </div>

    );
};

export default Index;