import { auth } from '..'

export const getTransactions = async (page, pageSize) => {
    const limit = pageSize;
    const res = await auth({
        method: "GET",
        url: `/wallet/transactions?page=${page}&limit=${limit}`,
    });
    return res;
}

export const getTotalSpends = async () => {
    const res = await auth({
        method: 'GET',
        url: '/wallet/total-spend'
    });

    return res?.data;
}
export const intiatePayment = async (data) => {
    const res = await auth({
        method: "POST",
        url: '/wallet/recharge',
        data
    })

    return res?.data;
};

export const authorizePayment = async (data) => {
    const res = await auth({
        method: 'POST',
        url: '/wallet/payment/authorize',
        data,
    })

    return res?.data;
};
// New Version

export const paymentStatus = async (data) => {
    const res = await auth({
        method: 'PUT',
        url: '/wallet/payment-status',
        data,
    });
    return res
}


export const getUserWalletDetails = async (data) => {
    const res = await auth({
        method: 'GET',
        url: '/wallet',
        data,
    });
    return res
}


export const getWalletRechargeDetails = async (data) => {
    const res = await auth({
        method: 'POST',
        url: '/wallet/recharge/',
        data,
    });
    return res
}

export const fetchCouponsBasedAmount = async (amount, module) => {
    const res = await auth({
        method: 'GET',
        url: `/coupons/suggested?amount=${amount}&module=${module}`,
    });
    return res
}

export const applyCoupon = async (couponCode, orderId) => {
    const res = await auth({
        method: 'POST',
        url: `/coupons/apply?coupon=${couponCode}&orderId=${orderId}`,
    });
    return res
}

export const fetchAllPlansCategories = async () => {
    const res = await auth({
        method: 'GET',
        url: "/wallet/plans/categories",
    });
    return res
}


export const fetchDurationAndPrice = async (id) => {
    const res = await auth({
        method: 'GET',
        url: `/wallet/plans/pricing/${id}`,
    });
    return res
}

export const fetchPlanComparison = async (categoryId) => {
    const res = await auth({
        method: 'GET',
        // url: `/wallet/plans/compare?plan_one=${planOne}&plan_two=${planTwo}`,
        url: `/wallet/plans/compare?plan_category=${categoryId}`,
    });
    return res
}

export const fetchAllPlansAndFeaturesBasedOnCategoryId = async (categoryId) => {
    const res = await auth({
        method: 'GET',
        url: `/wallet/plans/category/${categoryId}`,
    });
    return res
}

export const fetchPlanCategoryFeaturesBasedOnCategoryId = async (categoryId) => {
    const res = await auth({
        method: 'GET',
        url: `/wallet/plans/categories/features?plan_category=${categoryId}`,
    });
    return res
}

export const getQrCode = async () => {
    const res = await auth({
        method: 'POST',
        url: "/connections/qr-code",
    });
    return res
}

export const fetchWalletDetails = async () => {
    const res = await auth({
        method: 'GET',
        url: "/wallet/card",
    });
    return res
}


export const walletHistory = async (page, limit) => {
    const res = await auth({
        method: 'GET',
        url: `/wallet/transactions?page=${page}&limit=${limit}`,
    });
    return res
}


export const qrHistory = async () => {
    const res = await auth({
        method: 'GET',
        url: "/connections/history/qr",
    });
    return res
}

export const upgradePlan = async (data) => {
    const res = await auth({
        method: 'POST',
        url: "/wallet/plan/upgrade",
        data,
    });
    return res
}


export const qrConnectionRequest = async (data) => {
    const res = await auth({
        method: 'POST',
        url: "/connections/qr/sent",
        data,
    });
    return res
}