import { Suspense, lazy, useEffect, useState, useCallback } from "react"
import { toast } from "react-toastify"

import PlanCard from "./components/PlanCard"

import tickIcon from "../../../assets/wallet/icons/tick.svg"
import crossIcon from "../../../assets/wallet/icons/crossIcon.svg"
import alertIcon from "../../../assets/wallet/icons/alertIcon.svg"
import { fetchAllPlansCategories, fetchAllPlansAndFeaturesBasedOnCategoryId, paymentStatus, upgradePlan, fetchPlanCategoryFeaturesBasedOnCategoryId } from "apis/wallet"
import { useNavigate } from "react-router-dom"
import { WALLET } from "../../../shared/router"

import { useSelector } from "react-redux"
import { capitalize } from "shared/textFormats"

const RazorPay = lazy(() => import("components/payment").then(module => ({ default: module.RazorPay })))
const PaymentSummary = lazy(() => import("components/payment").then(module => ({ default: module.PaymentSummary })))
const PaymentSuccessPopUp = lazy(() => import("components/popUps").then(module => ({ default: module.PaymentSuccess })))
const DatrimonyPlanCard = lazy(() => import("./components/datrimonyPlanCard"))


function Index() {
    const userInfo = useSelector((state) => state.userProfile);
    const navigate = useNavigate()

    const [planCategoriesLimitationAndFeature, setPlanCategoriesLimitationAndFeature] = useState([])
    const [availablePlanCategories, setAvailablePlanCategories] = useState([])
    const [selectedPlanCategory, setSelectedPlanCategory] = useState()
    const [availablePlans, setAvailablePlans] = useState([])

    const [razoorpayPaymentStatus, setRazoorpayPaymentStatus] = useState(false)
    const [visible, setVisible] = useState({
        planPage: true,
        paymentSummary: false,
        razorpay: false,
        paymentSuccess: false,
    })
    const [paymentStructure, setPaymentStructure] = useState()
    const [paymentData, setPaymentData] = useState({
        orderId: null,
        total: null,
        paymentId: null,
        status: null,
        gst: null,
        coupon: null,
        discounted_amount: 0
    })

    const getPlansCategories = async () => {
        const res = await fetchAllPlansCategories();
        if (res?.success) {
            setAvailablePlanCategories(res?.data)
            setSelectedPlanCategory(res?.data[0]?.id)
        }
    };

    const getPlanCategoryFeaturesBasedOnCategoryId = async (categoryId = selectedPlanCategory) => {
        const res = await fetchPlanCategoryFeaturesBasedOnCategoryId(categoryId)
        setPlanCategoriesLimitationAndFeature(res?.data)
    }

    const getAllPlansAndFeaturesBasedOnCategoryId = async (categoryId = selectedPlanCategory) => {
        const res = await fetchAllPlansAndFeaturesBasedOnCategoryId(categoryId)
        if (res?.success) setAvailablePlans(res?.data)
    }


    function formatComparisonValue(value, name) {
        switch (value.type) {
            case 'unlimited':
                return 'Unlimited';
            case 'limit':
                // Add 'Hr' for specific names
                if (name === 'Expiry Time for Connection Requests' || name === 'Expiry Time for Meeting Requests') {
                    return `${value.limit}`;
                }
                return `${value.limit}`;
            case 'coin':
                return `${value.expiry}`;
            default:
                return 'N/A';
        }
    }

    useEffect(() => {
        getPlansCategories();
    }, []);

    useEffect(() => {
        if (selectedPlanCategory) {
            getPlanCategoryFeaturesBasedOnCategoryId();
            getAllPlansAndFeaturesBasedOnCategoryId()
        }
        // getMonthsAndAmountOfPlan()
    }, [selectedPlanCategory]);


    const handleChangeVisibility = useCallback((key) => {
        setVisible(prev => ({
            ...Object.keys(prev).reduce((acc, currentKey) => {
                acc[currentKey] = false;
                return acc;
            }, {}),
            [key]: true
        }));
    }, []);

    const onClickHandlePurchase = async (value) => {
        if (value.amount > 0) {
            const res = await upgradePlan({ planId: value.id })
            const data = { ...res.payment_structure, id: res?.data?.id }
            setPaymentData(prev => ({ ...prev, orderId: res?.data?.id }))
            setPaymentStructure(data)
            handleChangeVisibility("paymentSummary")
        } else {
            toast.error("Select a valid duration")
        }
    }

    const updateThePayment = async (data) => {
        const { total, ...other } = data
        const res = await paymentStatus(other)
        if (res?.success) {
            handleChangeVisibility('paymentSuccess')
        }
    }

    const razorpaySuccess = async (value) => {
        if (value?.status === 'failed') {
            handleChangeVisibility('paymentSuccess')
            setRazoorpayPaymentStatus(true)
        } else {
            const data = {
                ...paymentData,
                paymentId: value.paymentId,
                status: value.status
            }
            updateThePayment(data)
        }
    }

    const handlePay = (value) => {
        setPaymentData(prev => ({ ...prev, ...value }))
        handleChangeVisibility("razorpay")
    }

    const handleDoneBtn = () => {
        navigate(WALLET.WALLET)
    }


    return (
        <div className="flex flex-col h-full">


            <div className="w-full mx-auto h-full">

                {visible.razorpay && (
                    <Suspense fallback={<div>Loading...</div>}>
                        <RazorPay amount={paymentData.total} orderId={paymentData.orderId} handleOnclick={(value) => razorpaySuccess(value)} />
                    </Suspense>
                )}
                {visible.paymentSuccess && (
                    <Suspense fallback={<div>Loading...</div>}>
                        <PaymentSuccessPopUp handleDoneBtn={handleDoneBtn} isFailed={razoorpayPaymentStatus} data={paymentStructure} />
                    </Suspense>
                )}
                {visible.paymentSummary && (
                    <Suspense fallback={<div>Loading...</div>}>
                        <PaymentSummary data={paymentStructure} handlePay={(value) => handlePay(value)} />
                    </Suspense>
                )}
                {visible.planPage && (
                    <>
                        <div className="flex items-center justify-center w-full mt-5">
                            {availablePlanCategories.map((item) => (
                                <div key={item?.id} className={`p-2 text-black border border-solid cursor-pointer ${selectedPlanCategory === item?.id ? 'text-white bg-brandRed' : ''}`} onClick={() => setSelectedPlanCategory(item?.id)}>{capitalize(item.name)}</div>
                            ))}

                        </div>

                        <div className="grid w-full grid-cols-1 mt-5 md:grid-cols-3">
                            {availablePlans.map((item) => (
                                <>
                                    {selectedPlanCategory === '660184bc37c7a39c0eb85b89' ?
                                        <div className="mb-32">
                                            <Suspense fallback={<div>loading...</div>}>
                                                <DatrimonyPlanCard
                                                    key={item.id}
                                                    name={item.name}
                                                    amount={item?.amount}
                                                    coins={item?.coins}
                                                    currentPlan={userInfo.wallet.current_plan._id === item.id}
                                                    duration={item?.duration}
                                                    features={item?.features}
                                                />
                                            </Suspense>
                                        </div>
                                        :
                                        <PlanCard
                                            key={item.id}
                                            name={item.name}
                                            amount={item?.amount}
                                            coins={item?.coins}
                                            currentPlan={userInfo.wallet.current_plan._id === item.id}
                                            duration={item?.duration}
                                            features={item?.features}
                                            handlePurchase={() => onClickHandlePurchase(item)}
                                        />

                                    }
                                </>
                            ))}
                        </div>



                        {/*plan Content Section info */}
                        {selectedPlanCategory !== '660184bc37c7a39c0eb85b89' &&
                            <div className="flex ms-5">
                                <div className="w-full mt-32">
                                    <div className="flex items-center text-black">
                                        <p className="w-32 md:w-[290px] text-sm lg:text-[28px] font-bold">What you get</p>
                                    </div>
                                    {planCategoriesLimitationAndFeature?.access &&
                                        planCategoriesLimitationAndFeature?.access.map((item, index) => (
                                            <div key={index} className="flex items-center mt-7">
                                                <p className="text-sm font-semibold w-36 lg:text-xl md:w-[290px]">{item?.name}</p>
                                                <p className="w-[5rem] md:w-[290px] text-center flex items-center justify-center">
                                                    <span className="flex items-center justify-center"><img src={alertIcon} alt="" title={item?.info} className="cursor-pointer" /></span>
                                                </p>
                                                <p className="w-[5rem] md:w-[290px] text-center flex items-center justify-center">
                                                    <img src={item?.value === true ? tickIcon : crossIcon} alt="" />
                                                </p>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        }



                        {selectedPlanCategory !== '660184bc37c7a39c0eb85b89' &&
                            <div className="w-full h-[1px] bg-gray-300 mt-10"></div>
                        }

                        {/*user Content Section */}
                        {selectedPlanCategory !== '660184bc37c7a39c0eb85b89' &&
                            <div className="flex mb-20 ms-5">
                                <div className="mt-10">
                                    <div className="flex text-black">
                                        <p className="w-40 md:w-[250px] lg:w-[290px] text-lg lg:text-[28px] font-bold">User Limitations</p>
                                    </div>
                                    {planCategoriesLimitationAndFeature?.limits &&
                                        planCategoriesLimitationAndFeature?.limits.map((item, index) => (
                                            <div key={index} className="flex items-center justify-center mt-7">
                                                <p className="w-32 text-base font-semibold lg:text-xl md:w-60 text-start">{item.name}</p>
                                                <p className="w-[5.5rem] md:w-[250px] lg:w-[290px] text-center ">{formatComparisonValue(item.value, item.name)}</p>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        }

                    </>
                )}
            </div>
        </div>
    )
}

export default Index