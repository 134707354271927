import React, { useState, lazy, Suspense, useEffect, useCallback } from 'react'
import { toast } from "react-toastify";


import { UserBasicDetails, UserBasicDetailsResponsive, UserImgPreview } from 'components/discover';

// Assets
import { fetchMutualConnections, personalizedMatches, sendConnectionRequest, skipTheUser, updateLocation } from '../../apis/matches'
import { ConnectionActions } from 'components/discover';

const LocationError = lazy(() => import("components/popUps").then(module => ({ default: module.LocationTurnoff })))
const LimitReached = lazy(() => import("components/popUps").then(module => ({ default: module.LimitReached })))
const InsufficientCode = lazy(() => import("components/popUps").then(module => ({ default: module.InsufficientCoins })))
const NoAccess = lazy(() => import("components/popUps").then(module => ({ default: module.NodataAccess })))
const NoMatchesFound = lazy(() => import('components/Cards/emptyCards').then(module => ({ default: module.NoMatchesFound })))
const SomethingWentWrong = lazy(() => import("components/popUps").then(module => ({ default: module.SomethingWentWrong })))
const MutualConnection = lazy(() => import("components/popUps").then(module => ({ default: module.MutualConnection })))
const ProfileCompletionIsLessThan80 = lazy(() => import("components/popUps").then(module => ({ default: module.ProfileCompletionIsLessThan80 })))


function Index() {

  const [matches, setMatches] = useState()
  const [errorType, setErrorType] = useState(null)
  const [currentIndex, setCurrentIndex] = useState(0)
  const [mutualConnections, setMutualConnections] = useState([])
  const [mutualConnectionPopups, setMutualConnectionsPopups] = useState({
    count: false,
    users: false
  })


  const getLocation = async () => {
    if (!navigator.geolocation) {
      setErrorType('Please update your location to continue');
      toast('Geolocation is not supported by your browser');
      return;
    }
    navigator.geolocation.getCurrentPosition(
      async (position) => {
        await updateLocation({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude
        });
        fetchPersonalizedMatches()
      },
      () => {
        setErrorType('Please update your location to continue');
      }
    );
  };

  const fetchPersonalizedMatches = async () => {
    const res = await personalizedMatches()
    setMatches(res?.data)
    if (res?.response?.data?.type) {
      setErrorType(res?.response?.data?.type)
    }
  }

  useEffect(() => {
    getLocation()
    fetchPersonalizedMatches()
  }, [])


  // Send Connection Request

  const handleConnect = useCallback(async () => {
    setMutualConnectionsPopups({ count: false, users: false })
    const id = matches[currentIndex].matchId
    const res = await sendConnectionRequest(id)
    setCurrentIndex(prev => prev + 1);

    if (res?.response?.data) {
      setCurrentIndex(prev => prev - 1);
      setErrorType(res?.response?.data?.type)
    }

  }, [matches, currentIndex]);


  // Skip the user
  const handleSkip = useCallback(async () => {
    setMutualConnectionsPopups({ count: false, users: false })
    const id = matches[currentIndex].matchId
    const res = await skipTheUser(id)
    setCurrentIndex(prev => prev + 1);

    if (res?.response?.data) {
      setCurrentIndex(prev => prev - 1);
      setErrorType(res?.response?.data?.type)
    }
  }, [matches, currentIndex]);


  const ErrorComponent = errorType === 'Please update your location to continue' ? LocationError
    : (errorType === 'DAILY_CONNECTION_LIMIT' ? LimitReached
      : (errorType === 'DAILY_MATCH_LIMIT_REACHED' ? LimitReached
        : (errorType === 'INSUFFICIENT_COINS' ? InsufficientCode
          : (errorType === 'NO_ACCESS' ? NoAccess
            : errorType === 'profile_completion_less_than_80' ? ProfileCompletionIsLessThan80
              : SomethingWentWrong))))


  const handleMutualConnection = async () => {
    const id = matches[currentIndex].matchId
    const res = await fetchMutualConnections(id)
    setMutualConnections(res?.data)
    setMutualConnectionsPopups(prev => ({ ...prev, count: true }))
  }

  return (

    <div className='relative flex flex-col items-center justify-center w-full h-full min-h-screen mx-auto bg-[#efefef]'>

      {errorType && (
        <div className="fixed top-0 bottom-0 left-0 right-0 z-50 flex items-center justify-center w-full bg-black bg-opacity-75">
          <Suspense fallback={<div>Loading...</div>}>
            <ErrorComponent closeBtn={() => setErrorType(null)} />
          </Suspense>
        </div>
      )}

      {mutualConnectionPopups.users && (
        <div className="fixed top-0 bottom-0 left-0 right-0 z-50 flex items-center justify-center w-full bg-black bg-opacity-75">
          <Suspense fallback={<div>Loading...</div>}>
            <MutualConnection users={mutualConnections} closeBtn={() => setMutualConnectionsPopups(prev => ({ ...prev, users: false }))} />
          </Suspense>
        </div>
      )}

      <div className='flex flex-col items-center justify-center flex-grow w-full lg:flex-row'>
        {matches && matches.length > currentIndex ? (
          <div className='flex flex-wrap items-start justify-center w-full gap-0 md:gap-x-10 '>
            {/* ImgPreview Component */}
            <div className="flex flex-col items-center">
              <div className="h-[510px] w-full md:w-[360px] mx-auto flex items-center justify-center">
                <UserImgPreview data={matches[currentIndex]} />
              </div>
              <div className="flex items-center justify-center mt-4 gap-9">
                <ConnectionActions
                  handleSkip={handleSkip}
                  handleConnect={handleConnect}
                  handleMutualConnection={handleMutualConnection}
                />
              </div>
            </div>


            <div className="hidden md:block h-[580px] w-[60%] overflow-y-auto">
              <UserBasicDetails data={matches[currentIndex]} />
            </div>
            <div className="block mt-4 mb-10 overflow-y-auto md:hidden">
              <UserBasicDetailsResponsive data={matches[currentIndex]} />
            </div>
          </div>
        ) : (
          <Suspense fallback={<div>Loading...</div>}>
            <NoMatchesFound />
          </Suspense>
        )}
      </div>



      {/* Bottom Nav */}
      <div className='absolute flex flex-col items-center justify-center'>

        {mutualConnectionPopups.count &&
          <div className='w-full md:w-[339px] h-[64px] bg-black bg-opacity-75 backdrop-blur-sm text-white flex items-center p-4 justify-between'>
            <button onClick={() => setMutualConnectionsPopups(prev => ({ ...prev, count: false }))}>&#x2715;</button>
            <p className='mx-2 text-[12px] flex-grow'>You have {mutualConnections.length} Mutual Friends</p>
            {mutualConnections.length > 0 &&
              <button className='bg-white text-black p-2 w-[80px] text-center' onClick={() => setMutualConnectionsPopups({ count: false, users: true })} >Invite</button>
            }
          </div>
        }
      </div>

    </div>

  )
}

export default Index
