import React, { Suspense, lazy, useEffect } from 'react'
import Banner from './components/banner'
import { Helmet } from 'react-helmet'

const WhoWeAre = lazy(() => import("./components/whoWeAre"))
const WhyHostWithCS = lazy(() => import("./components/whyHostWithCS"))
const HowDoesItWork = lazy(() => import("./components/howDoesItWork"))
const HostWithUs = lazy(() => import("./components/hostWithUs"))
const FAQ = lazy(() => import("./components/FAQ"))

function Index() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    return (
        <div>

            <Helmet>
                {/* <meta name='title' content="Host with Us | Couple Squad Events | Make Your Event a Hit" /> */}
                {/* <meta name='description' content='Host with Us at Couple Squad Events! Get expert support and reach a wide audience to make your event a success. Create memorable experiences with us.' /> */}

                <title>Host with Us | Couple Squad Events | Make Your Event a Hit</title>
                <meta name="description" content="Host with Us at Couple Squad Events! Get expert support and reach a wide audience to make your event a success. Create memorable experiences with us." />
                <meta property="og:title" content="Host with Us | Couple Squad Events | Make Your Event a Hit" />
                <meta property="og:description" content="Host with Us at Couple Squad Events! Get expert support and reach a wide audience to make your event a success. Create memorable experiences with us." />
                <meta property="og:url" content="https://www.couplesquad.com/host-with-us" />
                <link rel="canonical" href="https://www.couplesquad.com/host-with-us" />
            </Helmet>


            {/* Main Banners */}
            <div className="mt-16">
                <Banner />
            </div>

            {/* Who We Are */}
            <div className="">
                <Suspense children={<WhoWeAre />} fallback={<div>loading...</div>} />
            </div>

            {/* Why Host With CS */}
            <div className="mt-10">
                <Suspense children={<WhyHostWithCS />} fallback={<div>loading...</div>} />
            </div>

            {/* How does it work */}
            <div className="mt-10">
                <Suspense children={<HowDoesItWork />} fallback={<div>loading...</div>} />
            </div>

            {/* Host With Us */}
            <div className="mt-10">
                <Suspense children={<HostWithUs />} fallback={<div>loading...</div>} />
            </div>

            {/*FAQ */}
            <div className="mt-10">
                <Suspense children={<FAQ />} fallback={<div>loading...</div>} />
            </div>

        </div>

    )
}

export default Index