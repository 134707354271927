import React, { useState, useEffect } from 'react'

// Assets
import coinIcon from "assets/wallet/icons/coin.svg"
import history from "assets/wallet/icons/transaction.svg"
import qrCode from "assets/wallet/icons/qrScanner.svg"
import plans from "assets/wallet/icons/planes.svg"
import invite from "assets/wallet/icons/invite.svg"
import myCard from "assets/wallet/icons/myCards.svg"

import { getUserWalletDetails } from '../../../apis/wallet'
import { useNavigate } from 'react-router-dom'
import avatar from "../../../assets/avatar.png"
import { WALLET } from '../../../shared/router'
import { getReferLink } from '../../../apis/home'
import { toast } from 'react-toastify'
import { planLabels } from 'assets/common/cloudinary'


function Index() {

    const navigate = useNavigate()
    const [walletData, setWalletData] = useState({
        name: '',
        photo: '',
        walletBalance: '',
        currentPlan: '',
        lastTransactionAmount: '',
        lastTransactionType: '',
    })
    const iconDetails = [
        { icon: history, label: 'History', action: () => navigate(WALLET.HISTORY) },
        { icon: qrCode, label: 'QR Code', action: () => navigate(WALLET.SCAN) },
        { icon: plans, label: 'Recharge', action: () => navigate(WALLET.RECHARGE) },
        { icon: invite, label: 'Invite', action: () => handleReferee() },
        { icon: myCard, label: 'Plan', action: () => navigate(WALLET.PLANES) },
    ];

    const handleWalletDetails = async () => {
        const res = await getUserWalletDetails()

        const data = res?.data
        setWalletData({
            name: data?.name,
            photo: data?.photo,
            walletBalance: data?.wallet?.balance,
            currentPlan: data?.wallet?.current_plan,
            lastTransactionAmount: data?.lastTransaction?.amount,
            lastTransactionType: data?.lastTransaction?.type,

        })
    }

    const handleReferee = async () => {
        const res = await getReferLink()
        if (res?.data) {
            if (!navigator.clipboard) {
                toast.error("Clipboard API not supported in this browser.");
            } else {
                await navigator.clipboard.writeText(res?.data);
                toast.success('link copied successfully')
            }
        }
    }

    useEffect(() => {
        handleWalletDetails()
    }, [])


    return (
        <div className="flex flex-col h-full">

            <div className="flex w-full ">
                <div className="w-[95%] mx-auto lg:w-[90%]">

                    <div className="mt-10 bg-[#df1a3c] rounded-md w-full h-[120px] md:h-[160px] flex justify-between text-white text-center">
                        {/* profile pic and name */}
                        <div className="flex items-center justify-between w-full">
                            <div className="m-4">
                                <div className="flex items-center justify-center">
                                    <img src={walletData.photo ? walletData?.photo : avatar} alt="" className='rounded-full hidden lg:block lg:h-[60px] lg:w-[60px]' />
                                    <div className="ml-2 text-xl font-medium text-white">
                                        <p className='text-sm md:text-base lg:text-xl'>Hello {walletData.name}</p>
                                        <p className='text-sm'>Available Balance</p>
                                    </div>
                                </div>
                                <img src={planLabels[walletData.currentPlan]} alt="" className='mt-2' />
                            </div>


                            {/* <div className="flex items-center justify-center ms-3">
                            </div> */}

                            <div className="flex items-start pt-5 mb-2 text-white me-5">
                                <p className='flex items-center gap-2 text-sm font-bold lg:text-xl ms-2'>
                                    <img src={coinIcon} alt="coinIcon" className='w-3 h-3 md:w-6 md:h-6' />
                                    {walletData.walletBalance}
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-wrap items-center gap-5 mt-10 lg:hidden">
                        {iconDetails.map(({ icon, label, action }, index) => (
                            <div onClick={action} key={index} className='text-base font-medium text-center text-black cursor-pointer md:text-xl'>
                                <img src={icon} alt={label} />
                                <p>{label}</p>
                            </div>
                        ))}
                    </div>

                    <div className="flex items-center gap-10 mt-10">
                        {iconDetails.map(({ icon, label, action }, index) => (
                            <div
                                onClick={action}
                                key={index}
                                className="flex flex-col items-center hidden text-base font-medium text-center text-black cursor-pointer md:text-xl lg:block"
                            >
                                <img src={icon} alt={label} className="object-contain w-12 h-12" />
                                <p className="mt-2 text-sm font-medium md:text-base">{label}</p>
                            </div>
                        ))}
                    </div>


                </div>
            </div>

        </div>
    )
}

export default Index