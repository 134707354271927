import React from 'react';
import { capitalize } from 'shared/textFormats';
import tickIcon from "assets/wallet/icons/tickWhiteIcon.svg"


function PlanCard({ name, amount, coins, currentPlan, duration, features = [], handlePurchase }) {

    const bgColors = {
        FLOW: 'bg-[#2c5588]',
        GROW: 'bg-[#ff8800]',
        CHAMPION: 'bg-[#ef69dd]',

        CONNECT: 'bg-[#199778]',
        EXPLORE: 'bg-[#6161e5]',
        DISCOVER: 'bg-[#070707]',
    }

    const handleClickPurchase = () => {
        if (!currentPlan) handlePurchase()
    }

    return (

        <div className={`p-4 w-[320px] md:w-[380px] h-full text-white`}>
            <div className={`${bgColors[name]} h-full rounded-lg`}>
                <div className="flex items-center w-full">
                    <p className={`w-full p-3 text-xl font-medium ${name === 'DISCOVER' ? 'bg-white text-black border-2 border-black' : 'bg-black'} `}>{name}</p>
                </div>
                <div className="w-[90%] mx-auto">
                    <h1 className="flex items-baseline my-2">
                        <h1 className="text-3xl font-bold">₹{amount}/</h1>
                        <p className="text-lg font-medium">{duration} months</p>
                    </h1>

                    <div className="flex items-center justify-between pb-5 m-2">
                        <h1>{coins} coins</h1>

                    </div>


                    <div className="m-2 text-lg font-semibold">Features</div>

                    <div className="mt-3 ">
                        {features?.map((item, index) => (
                            <div key={index} className="flex items-center p-1 space-x-2 rounded-m">
                                <img className='text-lg' src={tickIcon} alt="tickIcon" />
                                <span className="text-base text-white">
                                    {capitalize(item.name)} {item.count > 0 ? `(${item.count})` : ""}
                                </span>

                                {/* <span className="text-base text-white">{capitalize(item.name)} ({item?.count})</span> */}
                            </div>
                        ))}
                    </div>

                </div>
            </div>

            <div
                onClick={handleClickPurchase}
                className={`text-center bg-brandRed w-full text-white text-base rounded-md p-3 font-semibold mt-5 ${!currentPlan ? 'cursor-pointer' : ''}`}>{currentPlan ? "Current Plan" : "Purchase"}
            </div>
        </div>
    );
}

export default React.memo(PlanCard);