import React, { useEffect, useRef, memo } from 'react';
import avatar from "assets/avatar.png"
import { LazyImage } from 'components/image';
import { isHTML } from 'utils/helper';
import { chatRoomStatus } from 'socket/SocketManager';

function Index({ messages, currentUserId, currentUserPhoto, showMoreOption, markAsRead, isShowMore, shortListedUserId }) {

    const observer = useRef(null)

    useEffect(() => {
        chatRoomStatus(currentUserId, shortListedUserId, true)
        return () => {
            chatRoomStatus(currentUserId, shortListedUserId, false)
        }
    }, [])

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.1
        };

        observer.current = new IntersectionObserver((entries, observer) => {
            entries?.forEach(entry => {
                if (entry?.isIntersecting) {
                    const messageId = entry?.target?.getAttribute('data-id');
                    // Find the message in the messages array
                    const message = messages?.find(msg => msg?.id?.toString() === messageId);
                    // Check if the message's read status is false before marking as read
                    if (message && !message?.read && message?.sender_id?.id !== currentUserId) {
                        markAsRead(messageId);
                        observer?.unobserve(entry?.target);
                    }
                }
            });
        }, options);

        const messageElements = document.querySelectorAll('.message');
        messageElements?.forEach(message => {
            observer?.current?.observe(message);
        });

        return () => {
            if (observer?.current) {
                observer?.current?.disconnect();
            }
        };
    }, [messages, markAsRead]);



    return (
        <div className="flex flex-col p-4 text-center">
            {isShowMore && <p className='text-blue-500 cursor-pointer' onClick={() => showMoreOption(false)}>Show more</p>}

            {messages.map((message) => (
                <>
                    {((message?.blocked === false) || (message?.blocked === true && message?.sender_id?.id === currentUserId)) &&
                        <div key={message.id} data-id={message.id} className="mb-4 message">
                            <div className={`flex ${message.sender_id.id === currentUserId ? 'justify-end' : 'justify-start'}`}>
                                {message.sender_id.id !== currentUserId && (
                                    <LazyImage src={message.sender_id.photo ? message.sender_id.photo : avatar} alt="avatar" className="w-10 h-10 mr-2 rounded-full " />
                                )}
                                <div className={`p-2 rounded-lg flex items-center text-start max-w-[30.5rem] w-fit ${message.sender_id.id === currentUserId ? 'bg-red-500 text-white' : 'bg-gray-200 text-black'}`}>
                                    <div className='flex flex-col'>
                                        {/* {message.message} */}
                                        <div className="message">
                                            {isHTML(message.message) ? (
                                                <div dangerouslySetInnerHTML={{ __html: message.message }} />
                                            ) : (
                                                <p>{message.message}</p>
                                            )}
                                        </div>
                                    </div>
                                    {/* Double Tick for Read Status */}
                                    {message.read ? (
                                        <svg width="10" height="14" className='ms-2 min-h-3 min-w-3' viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9.19251 0.201296C8.92412 -0.0670986 8.52153 -0.0670986 8.25313 0.201296L3.22073 5.23369L1.14068 3.15364C0.872282 2.88524 0.46969 2.88524 0.201296 3.15364C-0.0670986 3.42203 -0.0670986 3.82462 0.201296 4.09302L2.75104 6.64276C2.88524 6.77696 3.01944 6.84406 3.22073 6.84406C3.42203 6.84406 3.55623 6.77696 3.69042 6.64276L9.19251 1.14068C9.46091 0.872283 9.46091 0.46969 9.19251 0.201296Z" fill="#45DA66" />
                                            <path d="M9.19251 7.04505C8.92412 6.77665 8.52153 6.77665 8.25313 7.04505L3.22073 12.0774L1.14068 9.99739C0.872282 9.72899 0.46969 9.72899 0.201296 9.99739C-0.0670986 10.2658 -0.0670986 10.6684 0.201296 10.9368L2.75104 13.4865C2.88524 13.6207 3.01944 13.6878 3.22073 13.6878C3.42203 13.6878 3.55623 13.6207 3.69042 13.4865L9.19251 7.98443C9.46091 7.71603 9.46091 7.31344 9.19251 7.04505Z" fill="#45DA66" />
                                        </svg>
                                    ) : (
                                        <svg width="10" height="7" className='ms-2 min-h-3 min-w-3' viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9.19251 0.201296C8.92412 -0.0670986 8.52153 -0.0670986 8.25313 0.201296L3.22073 5.23369L1.14068 3.15364C0.872282 2.88524 0.46969 2.88524 0.201296 3.15364C-0.0670986 3.42203 -0.0670986 3.82462 0.201296 4.09302L2.75104 6.64276C2.88524 6.77696 3.01944 6.84406 3.22073 6.84406C3.42203 6.84406 3.55623 6.77696 3.69042 6.64276L9.19251 1.14068C9.46091 0.872283 9.46091 0.46969 9.19251 0.201296Z" fill="#45DA66" />
                                        </svg>
                                    )}

                                </div>
                                {message.sender_id.id === currentUserId && (
                                    <LazyImage src={currentUserPhoto} alt="avatar" className="w-10 h-10 ml-2 rounded-full" />
                                )}
                            </div>

                            {/* Message Time */}
                            <div className={`mt-1 ${message.sender_id.id === currentUserId ? 'text-right me-14' : 'text-left ms-14'}`}>
                                <span className="text-xs">
                                    {new Date(message.createdAt).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                                </span>
                            </div>
                        </div>
                    }
                </>
            ))}
        </div>
    );
}

export default memo(Index);

