import React, { useEffect } from 'react';
import { formatDateToDDMMYY } from 'shared/dates';
import { getTheApp } from 'utils/getTheApp';

function ArticleContent({ blogData }) {
    let headingCounter = 0;
    let imageAltCounter = 0;



    const insertAdIntoContent = (content) => {
        if (!content) return '';

        const adHtml = blogData?.advertisement_center ? `
            <div style="text-align: center; margin: 20px 0;">
                <img class="ad-image w-full h-auto cursor-pointer"
                src="${blogData.advertisement_center.image_url}" 
                alt="${blogData.advertisement_center.alt_text || 'Advertisement'}"
               data-link="${blogData?.advertisement_center?.link}"
                 />
            </div>
        ` : '';

        if (content.includes("center_advertisement")) {
            return content.replace("center_advertisement", adHtml);
        } else {
            return `${content}${adHtml}`;
        }
    };

    const handleOnClickImage = (value) => {
        if (value === 'app_link') {
            getTheApp()
        } else {
            window.open(value, "_blank");
        }
    };

    const styledHtmlContent = insertAdIntoContent(blogData?.blog_content)
        .replace(/<h1>/g, '<h1 class="font-family-metrophobic-regular text-black" style="font-size: 2.5rem;">')
        .replace(/<h2>/g, () => {
            const id = `heading-${headingCounter++}`;
            return `<h2 id="${id}" class="font-family-metrophobic-regular text-black" style="font-size: 2rem;">`;
        })
        .replace(/<h3>/g, () => {
            const id = `heading-${headingCounter++}`;
            return `<h3 id="${id}" class="font-family-metrophobic-regular text-black" style="font-size: 1.7rem;">`;
        })
        // .replace(/<h3>/g, '<h3 style="font-size: 1.75rem;">')
        .replace(/<h4>/g, '<h4 class="text-black" style="font-size: 1.5rem;">')
        .replace(/<h5>/g, '<h5 class="text-black" style="font-size: 1.25rem;">')
        .replace(/<h6>/g, '<h6 class="text-black" style="font-size: 1rem;">')
        .replace(/<a /g, '<a target="_blank" class="text-brandRed" style="text-decoration: underline;" ')
        .replace(/<ul>/g, '<ul style="list-style-type: disc; margin-left: 20px;">')
        .replace(/<li>/g, '<li style="margin-bottom: 10px;">')
        .replace(/<img(?![^>]*class="ad-image")(?![^>]*alt=["'][^"']*["'])/g, () => {
            const altText = blogData.blog_content_image_alts?.[imageAltCounter] || 'Couple Squad';
            imageAltCounter++;
            return `<img alt="${altText}"`;
        });


    useEffect(() => {
        const adImage = document.querySelector('.ad-image');
        if (adImage) {
            adImage.addEventListener('click', (event) => {
                const link = event.target.getAttribute('data-link');
                handleOnClickImage(link);
            });
        }

        // Cleanup event listener on component unmount
        return () => {
            if (adImage) {
                adImage.removeEventListener('click', handleOnClickImage);
            }
        };
    }, [blogData]);

    const wrappedContent = `<div class="text-[#3e424b]">${styledHtmlContent}</div>`;



    return (
        <div className="p-4 text-black">
            <div className="mb-4">
                <h1 className="text-5xl font-bold text-black font-family-metrophobic-regular">{blogData?.title}</h1>
                <p className="text-gray-500">CoupleSquad &bull; {formatDateToDDMMYY(blogData?.createdAt)}</p>
            </div>

            <div className="mb-4" dangerouslySetInnerHTML={{ __html: wrappedContent }}></div>
        </div>
    );
}

export default ArticleContent;