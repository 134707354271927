import React, { memo } from 'react';
import { LazyImage } from 'components/image';
import { capitalize } from 'shared/textFormats';

const Index = ({ img, categoryName, eventName, fromToTime, fromToDate, EventPrice, isBooked = false, bg_color = false, isFreeForUser = false }) => {
    return (
        <div className={`relative w-full border-2 rounded-xl event-card ${bg_color ? bg_color : ''}`}>
            {categoryName &&
                <div className="absolute mt-5 ms-4 top-0 left-0 z-10 p-4 px-7 w-[43px] h-[21px] bg-gray-500 bg-opacity-50 text-white flex flex-col justify-center items-center rounded-sm">
                    {categoryName}
                </div>
            }
            <LazyImage src={img} alt="EventImage" className="event-image w-full rounded-t-xl max-h-[165px]" />
            <div className="text-black event-details ms-3">
                <h2 title={capitalize(eventName)} className="event-title text-lg font-medium my-3 mb-5 line-clamp-2 h-[57px]">{capitalize(eventName)}</h2>


                <div className='flex justify-between gap-1 mt-2 mb-4'>

                    <p className="mb-2 text-sm text-gray-500">{fromToTime}<br />{fromToDate}</p>
                    <div className="text-white me-5">
                        {isBooked ?
                            <button className='p-1 px-5 bg-[#3E45EB]'>
                                Booked
                            </button>
                            :
                            <>
                                {isFreeForUser ?
                                    <>
                                        <button className='p-1 bg-green-500 px-9'>
                                            Free
                                        </button>
                                    </>
                                    :
                                    <button className={`p-1 ${EventPrice === true ? 'bg-brandRed' : 'bg-black'} px-9`}>
                                        {EventPrice === true ? "Free" : `₹ ${EventPrice}`}
                                    </button>
                                }
                            </>

                        }
                    </div>

                </div>
            </div>

        </div>
    );
};

export default memo(Index);
