import { Suspense, lazy, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { ConnectionAcceptedFriendCard } from 'components/Cards/Connection'
import { getAllFriendsList } from '../../../apis/connections'
import { CONNECTIONS, CONVERSATION } from 'shared/router'
import { encodeToBase64 } from 'utils/encodeDecode'
import avatar from "../../../assets/profileEdit/basic/avaatar.png"
import { ConnectionTab } from 'components/NavbarAndTabs'
import { getFriendsAvailableForMeeting } from 'apis/meeting'

const NoConnectionsFound = lazy(() => import("components/Cards/emptyCards").then(module => ({ default: module.NoConnectionsFound })))
const ProfilePrevCard = lazy(() => import("./components/profilePrevCard"))

function Index() {
    const navigate = useNavigate()
    const location = useLocation();

    const [friendsLIstData, setFriendsLIstData] = useState([])
    const [isProfilePrev, setIsProfilePrev] = useState(false)
    const [nextPage, setNextPage] = useState(1)
    const [moduleType, setModuleType] = useState('friends')

    const handleGetAllFriendsList = async () => {
        if (nextPage) {
            const res = await getAllFriendsList(nextPage, 8)
            setNextPage(res?.pagination?.next_page)
            setFriendsLIstData(prev => ([...prev, ...(res?.data || [])]))
        }
    }

    const fetchFriendsAvailableForMeeting = async () => {
        if (nextPage) {
            const res = await getFriendsAvailableForMeeting(nextPage, 8)
            setNextPage(res?.pagination?.next_page)
            setFriendsLIstData(prev => ([...prev, ...(res?.data?.users || [])]))
        }
    }

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const type = queryParams.get("type");

        if (type === "meeting") {
            setModuleType(type)
            fetchFriendsAvailableForMeeting()
        } else {
            handleGetAllFriendsList()
        }
    }, [])

    const handleFriendsPreview = (id) => {
        navigate(CONNECTIONS.FRIEND_PREVIEW.replace(':id', encodeToBase64(id)));
    }

    return (
        <div className="flex flex-col h-full min-h-screen bg-[#f9f9f9]">
            {isProfilePrev ?
                <div className="flex items-center justify-center h-full min-h-screen">
                    <Suspense>
                        <ProfilePrevCard
                            id={isProfilePrev?.matchId}
                            name={isProfilePrev?.name}
                            photo={isProfilePrev.photo || avatar}
                            profession={isProfilePrev?.profession || null}
                        />
                    </Suspense>
                </div>
                :
                <>
                    <div className="flex flex-col items-center w-full bg-[#F9F9F9] mt-5">
                        <div className='w-10/12 h-full mt-3'>
                            <ConnectionTab count={friendsLIstData?.length} />
                        </div>
                    </div>

                    <div className="flex flex-col items-center justify-center  w-full bg-[#f9f9f9] mt-5 mb-20">
                        <div className='md:w-10/12 w-[70%] mt-3'>
                            {friendsLIstData?.length > 0 ? (
                                <div className='flex flex-wrap justify-start gap-6'>
                                    {friendsLIstData?.map((item, index) => (
                                        <div className='w-[300px] min-h-[400px]'>
                                            <ConnectionAcceptedFriendCard
                                                key={index}
                                                imgUrl={item?.photo ? item.photo : avatar}
                                                name={item?.name}
                                                // profession={item?.profession || null}
                                                viewUserProfile={() => handleFriendsPreview(item?.matchId)}
                                                handleBtnOnClick={() => {
                                                    moduleType === 'friends' ? setIsProfilePrev(item) : navigate(CONVERSATION.MEETING.replace(':id', encodeToBase64(item?.userId)))
                                                }}
                                                btnText={moduleType === 'friends' ? 'View' : 'Meeting'}
                                            />
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <div className='flex justify-center'>
                                    <Suspense fallback={<div>Loading...</div>}>
                                        <NoConnectionsFound decryption={"Your Mutual connections will be displayed here. Unfortunately, you don't have any mutual connections at the moment."} />
                                    </Suspense>
                                </div>
                            )}
                        </div>
                        {nextPage && (
                            <p className='text-lg text-blue-500 cursor-pointer' onClick={handleGetAllFriendsList}>see more...</p>
                        )}
                    </div>
                </>
            }
        </div>
    )
}

export default Index